@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-select
  +input
  position: relative
  display: flex
  align-items: flex-start
  justify-content: space-between
  .selected-option
    flex-grow: 1
    +ellipsis
  .dropdown-icon
    height: 0.625em
    width: 0.625em
    margin-top: .3125em
    margin-left: $form-input-indent
    border: 4px solid $form-control-color
    border-right: 0
    border-top: 0
    border-radius: 2px
    pointer-events: none
    transform: rotate(-45deg)
    transform-origin: center
    content: " "
    z-index: 4
  select
    -webkit-appearance: none
    -moz-appearance: none
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    padding: 0
    opacity: 0
    font-size: $form-font-size
    filter: alpha(opacity=0)
    visibility: visible
    cursor: pointer
    &::-ms-expand
      display: none
  &.is-inline
    justify-content: flex-start
    .selected-option
      flex-grow: 0
  &.is-disabled
    +disabled
    select
      cursor: default
    .dropdown-icon
      border-color: $form-disabled-color
  &:not(.is-disabled)
    &.has-error
      +error
      .dropdown-icon
        border-color: $form-error-color
  &.help
    +font('default')
    color: grey(5)
    +ellipsis
