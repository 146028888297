@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-segment-slideshow
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  overflow: hidden
  transition: opacity .6s ease-in
  opacity: 0
  z-index: $z-index-modal + 1
  &.is-visible
    opacity: 1
  .canvas
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    background-color: black()
    .menu
      flex-shrink: 1
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      width: 100%
      padding: m(2) m(2)
      z-index: $z-index-modal + 3
      .caption
        margin: 0
        white-space: nowrap
        overflow: hidden
        text-overflow: none
        color: white()
        transition: opacity .4s ease-out
        opacity: 1
        &.fade-enter
          opacity: 0
          transition: opacity .4s ease-in .3s
        &.fade-leave-active
          opacity: 0
          transition: opacity .4s ease-in .3s
    .image
      display: flex
      flex-direction: column
      flex-grow: 1
      padding: 0 m(2) m(2) m(2)
      z-index: $z-index-modal + 2
      .wrapper
        position: relative
        flex-grow: 1
        transition: transform .5s ease-out, opacity .3s ease-out
        &.slide-left-enter, // moving to right
        &.slide-right-leave-active
          opacity: 0
          transform: translate(10%, 0)
          transition: transform .5s ease-in, opacity .3s ease-in .2s
        &.slide-left-leave-active, // moving to left
        &.slide-right-enter
          opacity: 0
          transform: translate(-10%, 0)
          transition: transform .5s ease-in, opacity .3s ease-in .2s
        img
          position: absolute
          top: 50%
          bottom: 0
          left: 50%
          right: 0
          max-width: 100%
          max-height: 100%
          transform: translate(-50%,-50%)
      .cs-spinner
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
      .nav-image
        position: absolute
        top: 0
        height: 100%
        width: 40%
        z-index: $z-index-modal + 3
        .cs-button
          height: 100%
          width: 100%
        &.prev
          left: m(2)
          .cs-button
            justify-content: flex-start
        &.next
          right: m(2)
          .cs-button
            justify-content: flex-end
    .thumbs
      flex-shrink: 1
      display: flex
      flex-direction: row
      justify-content: center
      padding: m(2)
      z-index: $z-index-modal + 3
      .sideshow-elem-carousel
        .VueCarousel-wrapper
          .VueCarousel-inner
            .VueCarousel-slide
              .frame
                display: flex
                flex-direction: column
                // width and padding are set by style
                cursor: pointer
              .marker
                height: 3px
                margin-top: 2px
                background-color: transparent
              &.is-current
                .frame
                  cursor: default
                  .marker
                    background-color: white()
        .VueCarousel-pagination,
        .VueCarousel-navigation
          display: none !important
      .nav-thumbs
        flex-shrink: 1
        display: flex
        flex-direction: column
        justify-content: center
    &.is-single
      .nav-image
        display: none
      .thumbs
        display: none

+xs
  .cs-segment-slideshow
    .canvas
      .image
        .nav
          display: none
      .thumbs
        display: none
