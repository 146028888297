@charset "utf-8"

@import "../../../styles/lib/mixins.sass"
@import "../../../styles/lib/variables.sass"

.cs-page-home-welcome
  .fore
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-end
    padding: 0 content-padding()
    .brand
      padding-bottom: content-padding()
      h1
        font-family: $font-logo !important
        font-size: 10rem
        line-height: 1
        color: white(.9)
        whitespace: no-wrap
        text-transform: lowercase
      h2
        padding-left: .1em
        font-family: $font-logo-underline
        font-size: 4rem
        color: white(.8)
        letter-spacing: .05em
        whitespace: no-wrap
        text-transform: uppercase
    .scroll
      padding-bottom: m(2)
      left: 50%
      transform: translateX(-50%)
      &:before
        color: white(.8)

+md
  .cs-page-home-welcome
    .fore
      padding: 0 content-padding('md')
      .brand
        h1
          font-size: 8rem
        h2
          font-size: 3.2rem

+sm
  .cs-page-home-welcome
    .fore
      padding: 0 content-padding('sm')
      .brand
        h1
          font-size: 6.4rem
        h2
          font-size: 2.56rem

+xs
  .cs-page-home-welcome
    .fore
      padding: 0 content-padding('xs')
      .brand
        h1
          font-size: 4.8rem
        h2
          font-size: 1.92rem
