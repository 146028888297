@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-overlay
  position: fixed
  visibility: hidden
  top: 0
  left: 0
  right: 0
  bottom: 0
  opacity: 0
  background-color: black()
  transition: opacity .5s ease
  z-index: $z-index-modal - 1
  // define z-index in parent
  &.is-visible
    visibility: visible
    opacity: .7
    .cs-spinner
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
