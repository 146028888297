@charset "utf-8"

@import "../../../styles/lib/mixins.sass"
@import "../../../styles/lib/variables.sass"

.cs-page-home-chorus
  display: flex
  justify-content: center
  padding-top: headerheight()
  padding-bottom: m(6)
  background-color: white()
  .intro
    margin-bottom: m(3)
    h2
      margin-bottom: m(3)
      text-align: center
  .switch
    margin-bottom: m(3)
    .cs-col
      display: flex
      justify-content: center
      flex-wrap: wrap
      .cs-button
        margin: m(1) m(1)
  .members
    margin: 0 auto
    .member
      position: relative
      line-height: 0
      z-index: $z-index-base
  .repertoire
    p
      margin-bottom: m(2)
      +font('small', 'bold')
      text-transform: uppercase
    .left
      p
        text-align: right
  .barbershop
    h3
      margin-bottom: m(3)
      text-align: center

+md
  .cs-page-home-chorus
    padding-top: headerheight('md')

+sm
  .cs-page-home-chorus
    padding-top: headerheight('sm')
    .repertoire
      flex-wrap: wrap
      .left,
      .right
        width: 100%
        p
          margin-bottom: m(1)
          text-align: center

+xs
  .cs-page-home-chorus
    padding-top: headerheight('xs')
    .repertoire
      .left
        padding-bottom: 0

