@charset "utf-8"

@import "../../../styles/lib/mixins.sass"
@import "../../../styles/lib/variables.sass"

.cs-page-home-contact
  height: 100vh
  .fore
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-top: headerheight()
    padding-bottom: m(6)
    .contact
      flex-shrink: 0
      h2
        text-align: center
        color: black()
        margin-bottom: m(6)
      .box
        display: flex
        flex-direction: column
        justify-content: flex-start
        height: 100%
        padding: m(3)
        background-color: white(.7)
        h3
          color: black()
        p
          color: black()
        .cs-button
          margin-top: m(1)
        ul
          list-style-type: none
          margin-left: 0
    .footer
      flex-shrink: 0
      .nav-footer
        display: flex
        justify-content: center
        flex-wrap: wrap
        li
          padding: 0 m(1)
          +font('small', 'semibold')
          background-color: white(.7)
          a
            color: black()

+md
  .cs-page-home-contact
    .fore
      padding-top: headerheight('md')

+until(840px)
  .cs-page-home-contact
    .fore
      .contact
        .cs-row
          flex-wrap: wrap
          .cs-col
            width: 100%
        .box
          .address
            display: flex
            div
              margin-right: m(4)
          ul
            display: flex
            li
              margin-right: m(2)

+sm
  .cs-page-home-contact
    .fore
      padding-top: headerheight('sm')
      padding-bottom: m(3)

+xs
  .cs-page-home-contact
    .fore
      padding-top: headerheight('xs')
      padding-bottom: m(10)
      .contact
        .cs-row
          flex-wrap: wrap
          .cs-col
            width: 100%
        .box
          .address
            display: block
