@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-parallax-canvas
  height: 100vh
  overflow-x: hidden
  overflow-y: scroll
  perspective: 100px
  z-index: $z-index-base
  -webkit-overflow-scrolling: touch

body
  overflow: hidden
