//--------------------------------------------------------------------------
// Typo
//--------------------------------------------------------------------------

=font($var1: '', $var2: '')
  @if $var1 == 'light' or $var2 == 'light'
    font-family: $font-family-light
  @else if $var1 == 'regular' or $var2 == 'regular'
    font-family: $font-family-regular
  @else if $var1 == 'italic' or $var2 == 'italic'
    font-family: $font-family-italic
  @else if $var1 == 'semibold' or $var2 == 'semibold'
    font-family: $font-family-semibold
  @else if $var1 == 'bold' or $var2 == 'bold'
    font-family: $font-family-bold
  @else if $var1 == 'secondary' or $var2 == 'secondary'
    font-family: $font-family-secondary

  @if $var1 == 'tiny' or $var2 == 'tiny'
    font-size: font-size('tiny')
    line-height: line-height('tiny')
  @else if $var1 == 'small' or $var2 == 'small'
    font-size: font-size('small')
    line-height: line-height('small')
  @else if $var1 == 'default' or $var2 == 'default'
    font-size: font-size('default')
    line-height: line-height('default')
  @else if $var1 == 'medium' or $var2 == 'medium'
    font-size: font-size('medium')
    line-height: line-height('medium')
  @else if $var1 == 'big' or $var2 == 'big'
    font-size: font-size('big')
    line-height: line-height('big')
  @else if $var1 == 'bigger' or $var2 == 'bigger'
    font-size: font-size('bigger')
    line-height: line-height('bigger')
  @else if $var1 == 'jumbo' or $var2 == 'jumbo'
    font-size: font-size('jumbo')
    line-height: line-height('jumbo')

@function font-size-base ($breakpoint: 'lg')
  $key: breakpoint($breakpoint)
  @return nth($font-size-base, $key)

@function font-size($size: 'default')
  @if $size == 'tiny'
    @return nth($font-tiny, 1)
  @else if $size == 'small'
    @return nth($font-small, 1)
  @else if $size == 'default'
    @return nth($font-default, 1)
  @else if $size == 'medium'
    @return nth($font-medium, 1)
  @else if $size == 'big'
    @return nth($font-big, 1)
  @else if $size == 'bigger'
    @return nth($font-bigger, 1)
  @else if $size == 'jumbo'
    @return nth($font-jumbo, 1)

@function line-height($size: 'default')  
  @if $size == 'tiny'
    @return nth($font-tiny, 2)
  @else if $size == 'small'
    @return nth($font-small, 2)
  @else if $size == 'default'
    @return nth($font-default, 2)
  @else if $size == 'medium'
    @return nth($font-medium, 2)
  @else if $size == 'big'
    @return nth($font-big, 2)
  @else if $size == 'bigger'
    @return nth($font-bigger, 2)
  @else if $size == 'jumbo'
    @return nth($font-jumbo, 2)

//--------------------------------------------------------------------------
// Colors
//--------------------------------------------------------------------------

@function grey($key, $opacity: 1)
  $lightness: nth($grey, $key)
  @return hsla(0, 0%, $lightness, $opacity)

@function white($opacity: 1)
  @return grey(10, $opacity)

@function black($opacity: 1)
  @return grey(1, $opacity)

@function color($key, $opacity: 1)
  @if map-has-key($colors, $key)
    $color: map-get($colors, $key)
    @return hsla(hue($color), saturation($color), lightness($color), $opacity)
  @else
    @error "Invalid color name `#{$key}`."
    @return hsl(0, 0%, 0%)
  

//--------------------------------------------------------------------------
// Icons
//--------------------------------------------------------------------------
  
=icon($name: null, $position: 'before')
  @if $position == 'after'
    &:after
      font-family: $font-family-icons !important
      font-style: normal !important
      font-weight: normal !important
      font-variant: normal !important
      text-transform: none !important
      color: inherit
      speak: none
      line-height: 1
      -webkit-font-smoothing: antialiased
      -moz-osx-font-smoothing: grayscale
      @if $name
        content: map-get($icons, $name)
  @else
    &:before
      font-family: $font-family-icons !important
      font-style: normal !important
      font-weight: normal !important
      font-variant: normal !important
      text-transform: none !important
      color: inherit
      speak: none
      line-height: 1
      -webkit-font-smoothing: antialiased
      -moz-osx-font-smoothing: grayscale
      @if $name
        content: map-get($icons, $name)

//--------------------------------------------------------------------------
// Measure and distance helper
//--------------------------------------------------------------------------

@function content-width ($breakpoint: 'lg')
  $key: breakpoint($breakpoint)
  @return nth($content-width, $key) + (2 * nth($content-padding, $key))

@function content-padding ($breakpoint: 'lg')
  $key: breakpoint($breakpoint)
  @return nth($content-padding, $key)

@function col-gap ($breakpoint: 'lg')
  $key: breakpoint($breakpoint)
  @return nth($col-gap, $key)

@function headerheight ($breakpoint: 'lg')
  $height: map-get($headerheight, $breakpoint)
  @return #{$height}px

@function m ($num: 1)
  @return $measure * $num

@function breakpoint($name: 'lg')
  @if $name == 'md'
    @return 2
  @else if $name == 'sm'
    @return 3
  @else if $name == 'xs'
    @return 4
  @else
    @return 1

//--------------------------------------------------------------------------
// Helper
//--------------------------------------------------------------------------

=unselectable
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

=ellipsis
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

//--------------------------------------------------------------------------
// Elements
//--------------------------------------------------------------------------

=loader
  animation: spinAround 500ms infinite linear
  border: 2px solid grey(7)
  border-radius: $radius-rounded
  border-right-color: transparent
  border-top-color: transparent
  content: ""
  display: block
  height: 1em
  position: relative
  width: 1em

=placeholder
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input'
  @each $placeholder in $placeholders
    &:#{$placeholder}-placeholder
      @content

//--------------------------------------------------------------------------
// Responsive
//--------------------------------------------------------------------------

=from($width)
  @media screen and (min-width: $width + 1px)
    @content

=until($width)
  @media screen and (max-width: $width)
    @content

=lg
  $max: map-get($breakpoints, 'lg')
  @media screen and (max-width: #{$max}px)
    @content

=lg-only
  $min: map-get($breakpoints, 'md') + 1
  $max: map-get($breakpoints, 'lg')
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px)
    @content

=md
  $max: map-get($breakpoints, 'md')
  @media screen and (max-width: #{$max}px), print
    @content

=md-only
  $min: map-get($breakpoints, 'sm') + 1
  $max: map-get($breakpoints, 'md')
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px)
    @content

=sm
  $max: map-get($breakpoints, 'sm')
  @media screen and (max-width: #{$max}px)
    @content

=sm-only
  $min: map-get($breakpoints, 'xs') + 1
  $max: map-get($breakpoints, 'sm')
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px)
    @content

=xs
  $max: map-get($breakpoints, 'xs')
  @media screen and (max-width: #{$max}px)
    @content

=xs-only
  $max: map-get($breakpoints, 'xs')
  @media screen and (max-width: #{$max}px)
    @content

//--------------------------------------------------------------------------
// Shadows
//--------------------------------------------------------------------------

=soft-shadow
  box-shadow: 0 2px 10px rgba(0,0,0,.2)

//--------------------------------------------------------------------------
// Buttons
//--------------------------------------------------------------------------

=button-color($text, $background)
  color: $text
  background-color: $background
  border-color: $background
  &:not(.is-disabled):hover
    @if $background == 'transparent'
      //color: darken($text, 30%)
    background-color: darken($background, 10%)
    border-color: darken($background, 10%)
  &.is-outline,
  &.is-icon
    color: $background
    background-color: transparent
    &:not(.is-disabled):hover
      background-color: transparent
      color: darken($background, 10%)
      border-color: darken($background, 10%)
  &.is-icon 
    border-color: transparent
    &:not(.is-disabled):hover
      border-color: transparent
  &.is-disabled
    opacity: .4
    cursor: default

=button-size($font-size)
  padding: 0 .6em
  height: $button-height
  font-size: font-size($font-size) * 0.8
  &:before
    font-size: font-size($font-size)
  &.is-text
    padding: 0
  &.is-narrow
    padding: 0 .2em
    height: $button-height * .7
  &.is-full-width
    +ellipsis
    width: 100%
    max-width: 100%
    justify-content: center
  &.is-icon
    padding: 0
    height: $button-height
    width: $button-height
    justify-content: center
    align-items: center
    &:before
      padding: 0
    &.is-narrow
      height: $button-height * .7
      width: $button-height * .7
  &.icon-only // auto-set by cs-button
    &:before
      padding: 0

//--------------------------------------------------------------------------
// Inputs, Forms
//--------------------------------------------------------------------------

=input
  -webkit-appearance: none
  -moz-appearance: none
  max-width: 100%
  width: 100%
  padding: $form-vertical-padding $form-input-indent
  font-size: $form-font-size
  line-height: $form-line-height
  color: $form-color
  border: 1px solid $form-border-color
  box-shadow: none
  outline: none
  border-radius: $form-border-radius
  background-color: $form-background-color
  +placeholder
    color: $form-placeholder-color
  &:focus
    border-color: $form-control-color
    box-shadow: 0 0 0 0.125em rgba($form-control-color, 0.25)

=disabled
  +unselectable
  color: $form-disabled-color
  background-color: $form-disabled-background-color
  cursor: default
  &:focus
    border-color: $form-border-color
    box-shadow: none

=error
  padding: ($form-vertical-padding  - 1px) $form-input-indent
  border: 2px solid $form-error-color
  &:focus
    border-color: $form-error-color
    box-shadow: 0 0 0 0.125em rgba($form-error-color, 0.25)