@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-input
  position: relative
  display: flex
  align-items: flex-start
  input
    +input
  &.is-disabled
    input
      +disabled
  &:not(.is-disabled)
    &.has-error
      input
        +error
  &.help
    +font('default')
    color: grey(5)
    +ellipsis
