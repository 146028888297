@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-label
  display: flex
  align-items: baseline
  padding: 3px 0
  +font('tool', 'semibold')
  +ellipsis
  .required
    padding-left: 2px
    color: color('warning')
  &.has-error
    color: $form-error-color
  &.is-hidden
    display: none
  &.is-visible
    display: inline-flex
