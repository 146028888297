@charset "utf-8"

@import "../../../styles/lib/mixins.sass"
@import "../../../styles/lib/variables.sass"

.cs-page-home-cd
  display: flex
  justify-content: center
  padding-top: headerheight()
  padding-bottom: m(9)
  background-color: white()
  .intro
    padding-bottom: m(3)
    h2
      padding-bottom: m(3)
      text-align: center
    .cs-text
      text-align: center
  .products
    .cs-row
      justify-content: center
      .product
        display: flex
        flex-direction: column
        align-items: center
        flex: 0 1 auto
        width: 260px
        padding-bottom: m(3)
        .details
          display: flex
          flex-direction: column
          align-items: center
          .title
            display: block
            width: 240px
            padding-top: m(1)
            +font('semibold', 'medium')
            +ellipsis
            text-align: center
          .info
            display: flex
            justify-content: center
            align-items: center
            width: 100%
          .price
            +font('semibold', 'medium')
          .quantity
            display: flex
            justify-content: center
            width: 100%
            padding-top: m(1)
            .label
              margin-right: m(1)
            .cs-select
              width: 70px
              padding-top: 1px
              padding-bottom: 1px
  .titlelist
    padding-top: m(3)
    h3
      text-align: center
  .basket
    .cs-col
      display: flex
      justify-content: center
      align-items: center
      .total
        +font('medium', 'semibold')
      .shipping
        margin-left: m(1)
  .form
    padding-top: m(3)
  .error
    padding-top: m(3)
    padding-bottom: m(3)
    .cs-col
      background-color: color('warning')
      text-align: center
      *
        +font('semibold')
        color: white()
  .action
    .cs-col
      display: flex
      justify-content: center
      align-items: center
  .success
    padding-top: m(12)
    .cs-col
      background-color: color('success')
    .cs-text
      text-align: center
      *
        +font('semibold')
        color: white()
  
+md
  .cs-page-home-cd
    padding-top: headerheight('md')
    .products
      .cs-row
        .product
          width: 200px
          .details
            .title
              width: 180px
              +font('semibold', 'default')
            .price
              +font('semibold', 'default')

+sm
  .cs-page-home-cd
    padding-top: headerheight('sm')

+xs
  .cs-page-home-cd
    padding-top: headerheight('xs')
    .products
      .cs-row
        .product
          width: 180px
          .info
            .title
              width: 160px
    .titlelist
      .left
        padding-bottom: 0
        text-align: center
      .right
        padding-top: 0
        text-align: center
      ol
        margin-left: 0
        list-style-position: inside !important
