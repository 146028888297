//--------------------------------------------------------------------------
// Import some variables
//--------------------------------------------------------------------------
  
@import "../../config/style.json"

//--------------------------------------------------------------------------
// Typo
//--------------------------------------------------------------------------

// use font('regular')
$font-family-light:               zilla_regular, sans-serif
$font-family-regular:             zilla_regular, sans-serif
$font-family-italic:              zilla_regular, sans-serif
$font-family-semibold:            zilla_semibold, sans-serif
$font-family-bold:                zilla_semibold, sans-serif
$font-family-secondary:           outfit, sans-serif
$font-family-monospace:           monospace
$font-family-icons:               lf_icons
$font-logo:                       playfair_display, serif
$font-logo-underline:             outfit, sans-serif

// Base font size per breakpoint, only use in reset
// use font-size-base('xs')
$font-size-base:                  17px, 17px, 16px, 14px // was 18, 18, 17, 14

// Derived font-sizes to work with
// use font('medium') || font-size('medium') || line-height('medium')
$font-tiny:                       0.9rem, 1.3
$font-small:                      1.1rem, 1.3
$font-default:                    1.2rem, 1.5 // p
$font-medium:                     1.5rem, 1.5 // h3, blockquote
$font-big:                        3rem, 1.1 // h2
$font-bigger:                     3.5rem, 1.1 // h1
$font-jumbo:                      8rem, 1.1

//--------------------------------------------------------------------------
// Colors
//--------------------------------------------------------------------------

// white(opacity)
// black(opacity)
// grey([ 1 - 10 ], opacity), where 1 is black, 10 is white and 5 is medium grey
// color('orange', opacity)

//--------------------------------------------------------------------------
// Responsive
//--------------------------------------------------------------------------

// Values given are MAX-width of the device:
//
// 1441 - *  lg / widescreen
// 1440 - 835 is md / desktop (and tablet landscape mode)
// 834 - 481 is sm / tablet (portrait mode and mobile landscape mode)
// 480 - 0 is xs / mobile (for portrait mode only)
//
// https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
//
// Breakpoints are set to rounded value in maximum, because maximum widths
// of pictures etc. can be computed better.

//--------------------------------------------------------------------------
// Dimensions, padding, margin
//--------------------------------------------------------------------------

// number of columns
$col-count:                       12

// content-width + 2 x content-padding = width of content block
// use content-width('xs')
$content-width:                   1280px, 1280px, 930px, 464px // was 1380, 1380, ...

// left and right space between body and content
// use content-padding('xs')
$content-padding:                 30px, 30px, 24px, 18px

// space between columns, dividable by 2
// use col-gap('xs')
$col-gap:                         14px, 14px, 12px, 10px

// horizontal and vertical distance between text-blocks
// padding for textblocks with border or background
// use function: padding: m(3)
$measure:                         .4rem

// to be set
// use headerheight('xs')
// $headerheight:                   set in style.json

//--------------------------------------------------------------------------
// Borders, Radius
//--------------------------------------------------------------------------

$radius-small:                    3px
$radius:                          5px
$radius-large:                    8px
$radius-rounded:                  290486px

//--------------------------------------------------------------------------
// Buttons
//--------------------------------------------------------------------------

$button-height:                   2em // em, not rem, because different sizes

//--------------------------------------------------------------------------
// Form
//--------------------------------------------------------------------------
  
$form-font-size:                 font-size('default')
$form-line-height:               line-height('default')
$form-textarea-height:           8rem
$form-input-indent:              .6rem
$form-vertical-padding:          4px // px!
$form-color:                     grey(2)
$form-background-color:          white()
$form-disabled-color:            grey(5)
$form-disabled-background-color: grey(8)
$form-error-color:               color('warning')
$form-placeholder-color:         grey(6)
$form-border-color:              grey(6)
$form-border-radius:             $radius-small
$form-control-color:             color('primary')

//--------------------------------------------------------------------------
// Z-Index
//--------------------------------------------------------------------------

$z-index-base:                   100
$z-index-header:                 150
$z-index-tooltip:                200
$z-index-slider:                 700
$z-index-modal:                  800 // also slideshow