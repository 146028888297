@charset "utf-8"

@import "../../../styles/lib/mixins.sass"
@import "../../../styles/lib/variables.sass"

.cs-page-home-events
  display: flex
  justify-content: center
  padding-top: headerheight()
  padding-bottom: m(6)
  background-color: white()
  .intro
    margin-bottom: m(3)
    h2
      margin-bottom: m(3)
      text-align: center
  .more
    .cs-col
      display: flex
      justify-content: center

+md
  .cs-page-home-events
    padding-top: headerheight('md')

+sm
  .cs-page-home-events
    padding-top: headerheight('sm')

+xs
  .cs-page-home-events
    padding-top: headerheight('xs')

