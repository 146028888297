@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-checkbox
  position: relative
  display: flex
  align-items: flex-start // for multiline labels
  padding: 2px 0
  font-size: $form-font-size
  line-height: $form-line-height
  border-top: 1px solid transparent
  border-bottom: 1px solid transparent
  cursor: pointer
  input
    $margin-vertical: calc((($form-font-size * $form-line-height) - $form-font-size) / 2)
    position: relative
    flex-shrink: 0
    width: $form-font-size
    height: $form-font-size
    margin: $margin-vertical $form-input-indent $margin-vertical 0
    border: 1px solid $form-border-color
    border-radius: $form-border-radius
    background-color: $form-background-color
    transform: translateY(1px)
    -webkit-appearance: none
    -moz-appearance: none
    outline: none
    cursor: pointer
    &:checked
      &:after
        position: absolute
        display: block
        top: 50%
        height: 0.625em
        width: 1.25em
        margin-top: -0.4em
        margin-left: 0.25em
        border: 4px solid $form-control-color
        border-right: 0
        border-top: 0
        border-radius: 2px
        pointer-events: none
        transform: rotate(-45deg)
        transform-origin: center
        content: " "
  &.is-inline
    display: inline-flex
    margin-right: $form-input-indent * 3
  &.is-disabled
    input
      background-color: $form-disabled-background-color
      &:checked
        &:after
          border-color: $form-disabled-color
  &.has-error:not(.is-disabled)
    color: color('warning')
    input
      border-color: $form-error-color
      background-color: $form-error-color
      &:checked
        &:after
          border-color: white()
    p
      color: color('warning')
      a
        color: color('warning')
        text-decoration: underline
  &.help
    +font('default')
    color: grey(5)
    +ellipsis
