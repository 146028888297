@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-template-default
  z-index: $z-index-base
  .cs-page
    padding-top: headerheight()
    padding-bottom: m(3)
    transition: all .4s cubic-bezier(.55,0,.1,1)
    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity .5s ease
    &.fade-enter,
    &.fade-leave-active
      opacity: 0
  .footer
    flex-shrink: 0
    padding-bottom: m(6)
    .nav-footer
      display: flex
      justify-content: center
      flex-wrap: wrap
      li
        margin: 0 m(1)
        +font('small', 'semibold')

+md
  .cs-template-default
    .cs-page
      padding-top: headerheight('md')

+sm
  .cs-template-default
    .cs-page
      padding-top: headerheight('sm')

+xs
  .cs-template-default
    .cs-page
      padding-top: headerheight('xs')

