@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"


// on-canvas
$nav-main-color:                 white()
$nav-main-hover-color:           white()
$nav-main-active-color:          white()

// for off-canvas slider
$nav-slider-bg-color:            white()
$nav-slider-border-color:        grey(7)

$nav-slider-main-bg-color:       white()
$nav-slider-main-color:          grey(3)
$nav-slider-main-hover-color:    color('linkactive')
$nav-slider-main-active-color:   black()

// the off-canvas open button
$nav-toolbar-height:             3rem
$nav-toolbar-padding:            m(2)
$nav-button-bg-color:            white()
$nav-button-closed-color:        white() // closed status
$nav-button-open-color:          grey(5) // open status

.cs-navigation
  width: 100% // important, when navigation is fixed, put in smaller container when too wide
  &.on-init
    visibility: hidden
  &.on-canvas
    position: relative
    .nav-slider
      .nav-viewbox
        display: flex
        justify-content: flex-start
        .nav-main,
        .nav-lang
          &.level-1
            position: relative
            display: flex
            line-height: 1
            >.item
              display: flex
              flex-direction: column
              padding: 0 m(1)
              &:last-child
                border-right: none
              >.cs-link
                display: flex
                align-items: center
                position: relative
                height: 100%
                padding-top: 3px
                +font('semibold', 'small')
                line-height: 1.1
                color: $nav-main-color
                text-transform: uppercase
                white-space: nowrap
                &:hover
                  color: $nav-main-hover-color
                &:before // optional icon
                  padding-right: m(1)
                  +font('medium')
                &:after // optional icon
                  padding-left: m(1)
                  +font('medium')
              &:after
                display: block
                align-self: center
                content: ""
                height: 2px
                width: 0px
                transition: width .2s ease
                background-color: transparent
              &.is-active
                >.cs-link
                  color: $nav-main-active-color
                &:after
                  width: 100%
                  background-color: $nav-main-active-color
              &:hover
                &.is-active
                  >.cs-link
                    color: $nav-main-active-color
                  &:after
                    background-color: $nav-main-active-color
              &.is-facebook
                >.cs-link
                  font-size: 0
                  +icon('facebook')
                  &:before
                    padding-right: 0
              &.is-instagram
                >.cs-link
                  font-size: 0
                  +icon('instagram')
                  &:before
                    padding-right: 0
        .nav-main
          margin-left: - m(1)
        .nav-lang
          margin-right: - m(1)
          &.level-1
            .item
              &.is-active
                &:after
                  background-color: transparent

  &.off-canvas
    .nav-slider
      visibility: hidden
      position: fixed
      top: 0
      right: 0
      height: 100%
      width: auto
      min-width: 300px
      max-width: 100%
      padding: $nav-toolbar-height 0 0 0
      background-color: $nav-slider-bg-color
      z-index: $z-index-slider + 10
      transform: translateX(100%)
      +soft-shadow
      &.is-animated
        transition: transform .5s ease 0s, visibility 0s linear .5s
      &.is-open
        visibility: visible
        transform: translateX(0)
        &.is-animated
          transition: transform .5s ease 0s
      .nav-viewbox
        display: flex
        flex-direction: column
        height: 100%
        overflow-x: hidden
        overflow-y: scroll
        -webkit-overflow-scrolling: touch
        .item
          .cs-link
            display: flex
            align-items: center
            position: relative
            margin: m(.5) m(4)
            white-space: nowrap
            text-overflow: ellipsis
            cursor: pointer
            &:after // for additional icons (not :before, this is for dropdown-icon)
              flex: 0 1 auto
              margin-left: m(.5)
          &.has-dropdown
            >.cs-link
              display: flex
              flex-direction: row-reverse
              justify-content: flex-end
              align-items: center
              +icon('down')
              &:before
                margin-left: 4px
                font-size: .6em
            .level-2
              visibility: hidden
              height: 0
              .item
                .cs-link
                  padding-left: m(6)
            &.is-open
              .level-2
                visibility: visible
                height: 100%
        .nav-main,
        .nav-lang
          order: 1
          padding-top: m(2)
          padding-bottom: m(2)
          background-color: $nav-slider-main-bg-color
          border-top: 1px solid $nav-slider-border-color
          .item
            .cs-link
              color: $nav-slider-main-color
              &:hover
                color: $nav-slider-main-hover-color
            >.cs-link
              &:before
                transform: rotate(-90deg)
                transition: transform .1s linear
            &.is-extern
              .cs-link
                +icon(link, after)
                &:hover
                  &:before,
                  &:after
                    color: $nav-slider-main-hover-color
            &.is-open
              >.cs-link
                &:before
                  transform: translateY(2px) rotate(0deg)
            &.is-active
              >.cs-link
                color: $nav-slider-main-active-color
              &.is-open
                >.cs-link
                  color: $nav-slider-main-color
    .nav-button
      position: fixed
      right: 0
      top: 0
      height: $nav-toolbar-height
      cursor: pointer
      z-index: $z-index-slider + 20
      .icon
        position: absolute
        top: $nav-toolbar-padding
        right: content-padding('sm')
        width: 28px
        height: 28px
        span
          position: absolute
          display: block
          background-color: $nav-button-closed-color
          height: 3px
          width: 100%
          border-radius: 3px
          &:nth-child(1)
            top: 12%
            transition: top .1s linear .2s, height 0s linear .2s, background-color .2s linear .3s
          &:nth-child(2)
            top: calc(50% - 2px)
            transition: transform .2s linear, background-color .2s linear .3s
          &:nth-child(3)
            top: calc(50% - 2px)
            transition: transform .2s linear, background-color .2s linear .3s
          &:nth-child(4)
            top: calc(88% - 3px)
            transition: top .1s linear .2s, height 0s linear .2s, background-color .2s linear .3s
      &.is-open
        .icon
          span
            background-color: $nav-button-open-color
            &:nth-child(1)
              height: 0
              top: calc(50% - 2px)
              transition: top .1s linear 0s, height 0s linear 0s
            &:nth-child(2)
              transform: rotate(45deg)
              transition: transform .2s linear
            &:nth-child(3)
              transform: rotate(-45deg)
              transition: transform .2s linear
            &:nth-child(4)
              height: 0
              top: calc(50% - 2px)
              transition: top .1s linear 0s, height 0s linear 0s
    .cs-overlay
      z-index: $z-index-slider - 10
+xs
  .cs-navigation
    &.off-canvas
      .nav-slider
        min-width: 75%
      .nav-button
        .icon
          //right: content-padding('xs')
