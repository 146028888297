@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"


// view-1
.cs-element-event
  margin-bottom: m(3)
  .cs-col
    display: flex
    flex-direction: column
    &.col1
      width: 12%
    &.col2
      width: 20%
      .title
        display: none
    &.col3
      width: 46%
      .venue
        display: none
      .info
        padding-top: m(2)
        p
          color: grey(4)
    &.col4
      flex-direction: column
      justify-content: flex-start
      width: 22%
      .is-warning
        +font('semibold')
        color: color('warning')
      .is-comment
        color: grey(6)
      .buttons
        display: flex
        flex-align: center
        padding-top: 3px
        >.cs-button,
        >.cs-link
          margin: 0 m(1)
          &:first-child
            margin-left: 0
          &:last-child
            margin-right: 0

// view-2
+until(1200px)
  .cs-element-event
    .cs-col
      &.col1
        width: 18%
        padding-bottom: 0
      &.col2
        width: 24%
        padding-bottom: 0
      &.col3
        width: 58%
        padding-bottom: 0
      &.col4
        width: 58%
        margin-left: 42%
        padding-top: 0

// view-3
+until(900px)
  .cs-element-event
    .cs-col
      &.col1
        width: 22%
        .venue
          display: none
      &.col2
        width: 78%
        .title
          display: inline
        .venue
          display: none
      &.col3
        width: 78%
        margin-left: 22%
        padding-top: 0
        .venue
          display: inline
        .title
          display: none
        .address
          display: none
      &.col4
        width: 78%
        margin-left: 22%

// view-4
+until(600px)
  .cs-element-event
    .cs-col
      &.col1
        width: 100%
        display: flex
        flex-direction: row
        padding-bottom: 0
        .date
          margin-right: m(1)
      &.col2
        width: 100%
        padding-top: 0
        .town
          display: none
      &.col3
        width: 100%
        margin-left: 0
        padding-top: 0
      &.col4
        width: 100%
        margin-left: 0

