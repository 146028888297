@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-parallax-segment
  position: relative
  width: 100vw
  &.is-parallax
    height: 100vh // default height, because without height parallax doesn't work. Can be overwritten is parent style.
    transform-style: preserve-3d
  .back,
  .fore
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
  .fore
    z-index: $z-index-base
  .back
    z-index: $z-index-base - 1
