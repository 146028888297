@charset "utf-8"

@import "../../../styles/lib/mixins.sass"
@import "../../../styles/lib/variables.sass"

.cs-page-home-media
  height: 100vh
  .fore
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-top: headerheight()
    padding-bottom: m(10)
    .intro
      flex-shrink: 0
      h2
        margin-bottom: m(3)
        text-align: center
        color: white()
    .media
      flex-shrink: 0
      .videos
        justify-content: center
        margin-bottom: m(3)
        .video
          min-width: 480px
          .box
            padding: 6px
            background-color: white()
          .title
            padding-top: m(1)
            +font('semibold')
            color: white()
      .links
        .cs-col
          display: flex
          justify-content: center
          .cs-button
            margin: 0 m(1)


+md
  .cs-page-home-media
    .fore
      padding-top: headerheight('md')

+sm
  .cs-page-home-media
    .fore
      padding-top: headerheight('sm')
      padding-bottom: m(3)

+xs
  .cs-page-home-media
    .fore
      padding-top: headerheight('xs')
      .media
        .videos
          .video
            min-width: 100%
