@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-textarea
  position: relative
  display: flex
  align-items: flex-start
  textarea
    +input
    resize: vertical
    &:not([rows])
      height: $form-textarea-height
      min-height: $form-textarea-height
      max-height: $form-textarea-height * 3
    &[rows]
      height: initial
    &:focus
      border-color: $form-control-color
      box-shadow: 0 0 0 0.125em rgba($form-control-color, 0.25)
  &.is-disabled
    textarea
      +disabled
  &:not(.is-disabled)
    &.has-error
      textarea
        +error
  &.has-fixed-size
    textarea
      resize: none
  &.is-small
    textarea
      &:not([rows])
        height: $form-textarea-height * .5
        min-height: $form-textarea-height * .5
        max-height: $form-textarea-height * 1.5
  &.is-medium
    textarea
      &:not([rows])
        height: $form-textarea-height * 1.5
        min-height: $form-textarea-height * 1.5
        max-height: $form-textarea-height * 4.5
  &.is-big
    textarea
      &:not([rows])
        height: $form-textarea-height * 2
        min-height: $form-textarea-height * 2
        max-height: $form-textarea-height * 6
  &.help
    +font('default')
    color: grey(5)
    +ellipsis
