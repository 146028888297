@charset "utf-8"

@import "../../../styles/lib/mixins.sass"
@import "../../../styles/lib/variables.sass"

.cs-page-home-join
  height: 100vh
  .fore
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-top: headerheight()
    padding-bottom: m(10)
    .intro
      flex-shrink: 0
      h2
        margin-bottom: m(3)
        text-align: center
        color: white()
      p
        margin-bottom: m(3)
        +font('semibold')
        color: white()
        text-align: center
    .details
      flex-shrink: 0
      .box
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 100%
        padding: m(3)
        background-color: color('primary', .7)
        h3
          color: white()
        p
          color: white()
        .cs-button
          margin-top: m(1)

+md
  .cs-page-home-join
    .fore
      padding-top: headerheight('md')

+until(720px)
  .cs-page-home-join
    .fore
      .details
        .cs-row
          flex-wrap: wrap
          .cs-col
            width: 100%

+sm
  .cs-page-home-join
    .fore
      padding-top: headerheight('sm')
      padding-bottom: m(3)
      .intro
        min-height: 30vh

+xs
  .cs-page-home-join
    .fore
      padding-top: headerheight('xs')
